<template>
  <div v-if="userData">
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Thông tin cá nhân
        </b-card-title>
        <b-button
          v-b-modal.modal-profile-update
          variant="outline-secondary"
        >
          Cập nhật
        </b-button>
      </b-card-header>
      <hr class="mt-0">
      <b-card-body>

        <dl class="row">
          <dt class="col-sm-3" />
          <dd class="col-sm-9">
            <avatar-view
              :src="userData.avatar"
              @on-clicked="testShowGallery"
            />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">
            Họ tên
          </dt>
          <dd class="col-sm-9">
            {{ userData.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">
            Số điện thoại
          </dt>
          <dd class="col-sm-9">
            {{ userData.phone }}

            <feather-icon
              v-if="userData.phoneVerified"
              variant="primary"
              color="primary"
              class="text-primary"
              icon="ShieldIcon"
            />
            <b-button
              v-if="!userData.phoneVerified"
              v-b-modal.modal-verify-phone
              variant="flat-warning"
              size="sm"
              class="ml-50"
              @click="requestMissCallOTP"
            >
              <feather-icon
                icon="SmartphoneIcon"
                class="mr-50"
              />
              <span class="align-middle">Xác minh ngay</span>
            </b-button>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3 text-truncate">
            Email
          </dt>
          <dd class="col-sm-9">
            {{ userData.email }}
            <feather-icon
              v-if="userData.emailVerified && userData.email && userData.email.length > 0"
              variant="primary"
              color="primary"
              class="text-primary"
              icon="ShieldIcon"
            />
            <b-button
              v-if="!userData.emailVerified && userData.email && userData.email.length > 0"
              variant="flat-warning"
              size="sm"
              class="ml-50"
              @click="verifyEmail"
            >
              <feather-icon
                icon="MailIcon"
                class="mr-50"
              />
              <span class="align-middle">Xác minh ngay</span>
            </b-button>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">
            Ngày sinh
          </dt>
          <dd class="col-sm-9">
            {{ userData.birthday ? parseDateToString(userData.birthday) : 'Chưa có thông tin' }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">
            Giới tính
          </dt>
          <dd class="col-sm-9">
            {{ userData.gender ? userData.gender.name : 'Chưa có thông tin' }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">
            CMND/CCCD
          </dt>
          <dd class="col-sm-9">
            {{ userData.idNumber ? `Số ${userData.idNumber} ${userData.issueDate ? `cấp ngày ${parseDateToString(userData.issueDate)}` : "" } ${userData.placeOfIssue ? `tại ${userData.placeOfIssue}` : "" } ` : 'Chưa có thông tin' }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">
            Địa chỉ
          </dt>
          <dd class="col-sm-9">
            {{ userData.fullAddress ? userData.fullAddress : 'Chưa có thông tin' }}
          </dd>
        </dl>
        <dl
          v-if="userData.role && userData.role.expiredIn"
          class="row"
        >
          <dt class="col-sm-3">
            Hạn sử dụng
          </dt>
          <dd class="col-sm-9">
            {{ userData.role && userData.role.expiredIn ? parseDateToString(userData.role.expiredIn) : 'Chưa có thông tin' }}
          </dd>
        </dl>
      </b-card-body>
    </b-card>
    <profile-update-modal
      :item="{...userData, avatarFile: null}"
      @on-item-updated="onProfileUpdated"
    />
    <verify-phone :user-data="userData" />
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody, BButton, VBModal,
} from 'bootstrap-vue';
import AvatarView from '@/views/components/AvatarView.vue';
import useJwt from '@/auth/jwt/useJwt';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { $themeConfig } from '@themeConfig';
import { parseDateToString } from '@/auth/utils';
import ProfileUpdateModal from './ProfileUpdateModal.vue';
import VerifyPhone from './VerifyPhone.vue';

export default {
  components: {
    BCard,
    BCardTitle,
    BButton,
    BCardHeader,
    BCardBody,
    ProfileUpdateModal,
    AvatarView,
    VerifyPhone,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onProfileUpdated() {
      this.$emit('on-profile-updated');
    },
    testShowGallery() {
      $themeConfig.layout.gallery = true;
    },
    requestMissCallOTP() {
      useJwt.requestVerifyPhoneOtp({ phone: this.userData.phone }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Thông báo`,
            icon: 'PhoneIcon',
            variant: 'success',
            text: `Vui lòng kiểm tra điện thoại, sẽ có số điện thoại gọi đến. Hãy lấy 4 số cuối của số điện thoại gọi đến làm mã OTP`,
          },
        });
      })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Thông báo`,
              icon: 'EmailIcon',
              variant: 'danger',
              text: error.response.data.message ? error.response.data.message : 'Có lỗi xảy ra, vui lòng thử lại sau',
            },
          });
        });
    },
    verifyEmail() {
      useJwt.requestVerifyEmail()
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Thông báo`,
              icon: 'EmailIcon',
              variant: 'success',
              text: `Vui lòng kiểm tra email của bạn và làm theo hướng dẫn. Nếu không nhận được email, hãy cố gắng kiểm tra trong thư mục Spam.`,
            },
          });
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Thông báo`,
              icon: 'EmailIcon',
              variant: 'danger',
              text: error.response.data.message ? error.response.data.message : 'Có lỗi xảy ra, vui lòng thử lại sau',
            },
          });
        });
    },
    previewAvatar() {

    },
  },
  setup() {
    return { parseDateToString };
  },
};
</script>
