<template>
  <div v-if="role">

    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Thông tin gói cước
        </b-card-title>
      </b-card-header>
      <hr class="mt-0">
      <b-card-body>

        <dl class="row">
          <dt class="col-sm-3">
            Ngày hết hạn:
          </dt>
          <dd class="col-sm-9">
            {{ parseDateToString(role.expiredIn) }}

          </dd>
        </dl>

      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody,
} from 'bootstrap-vue';
import { getUserRole, parseDateToString } from '@/auth/utils';

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
  },

  setup() {
    const role = getUserRole();

    return { role, parseDateToString };
  },
};
</script>
