<template>
  <b-form-group
    label="Quận/Huyện"
    :state="state"
  >
    <template v-slot:label>
      Quận/Huyện
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="district"
      v-model="district"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="name"
      :options="districts"
      placeholder="Chọn quận/huyện"
      :disabled="province == null"
      @input="districtChanged"
      @open="onOpen"
    ><!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        Không có dữ liệu
      </template></v-select>
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    province: {
      type: Object,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      district: this.defaultValue,
      districts: [],
    };
  },

  watch: {
    province(newVal) {
      this.district = null;
      this.districtChanged();
      if (newVal) {
        this.getDistrictsByProvince(newVal);
      } else {
        this.districts = [];
      }
    },
  },
  methods: {
    getDistrictsByProvince(province) {
      if (province) {
        useJwt
          .getDistricts(province.id)
          .then(response => {
            this.districts = response.data;
          })
          .catch(() => {
            this.districts = [];
          });
      } else {
        this.districts = [];
      }
    },

    districtChanged() {
      this.$emit('input', this.district);
    },

    onOpen() {
      if (this.districts.length === 0) {
        this.getDistrictsByProvince(this.province);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
